@font-face {
  font-family: "AsteroneBold";
  src: url("./fonts/Asterone-bold.ttf") format("ttf");
  font-weight: 700;
  font-style: bold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
